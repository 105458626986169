import {Container} from "@/components/Container";
import Image from "next/image";
import Link from "next/link";
import {ChevronRight} from "@/images/ChevronRight";
import {ArrowDownRightIcon} from "@/images/ArrowDownRightIcon";
import {ProjectCard} from "@/components/ProjectCard";
import beam from '@/images/hero/beam.png';
import getProjects from "@/content/projects";
import {getHeroProjects} from "@/content/projects";
import {useContext} from "react";
import {ContactModalContext} from "@/context/ContactModalContext";
import {FadeIn, FadeInStagger} from "@/components/FadeIn";
import {useTranslations} from "next-intl";

export function Hero() {
    const {setContactModalOpen} = useContext(ContactModalContext);
    const t = useTranslations('Hero');
    const heroProjects = getHeroProjects(useTranslations('projects'))

    return <header className="bg-white " id="hero">
        <div className="bg-midnight section-rounded-b relative overflow-hidden">
            <Image src={beam} alt=""
                   className="absolute -top-[1rem] left-1/2 -ml-[60rem] w-[163.125rem] max-w-none sm:-ml-[67.5rem]"
                   priority/>

            <Container className="pt-32 md:py-48 text-left relative">

                <FadeInStagger className="col-span-1">
                    <FadeIn>
                        <h1 className="text-7xl md:text-9xl font-semibold py-5 text-transparent font-gradient font-effect-hero">
                            {/*Maatwerk software, zonder zorgen*/}
                            {/*Maatwerk software waar je klanten versteld van staan*/}
                            {/*Transformeer je ideeën in krachtige software-oplossingen*/}
                            D&apos;Agostino <br/>Digital
                        </h1>
                    </FadeIn>
                    <FadeIn>
                        <p className="my-2 md:my-5 text-2xl md:text-3xl px-0 max-w-xl">
                            <span className="opacity-80">{t('subtitle')}</span>
                            {/*<span className="opacity-70">Softwareontwikkeling kan ook anders. Met een transparante en doorgewinterde</span>{' '}*/}
                            {/*<span className="opacity-100">werkwijze</span> <span className="opacity-70">maak ik softwareontwikkeling leuk en toegankelijk.</span>*/}
                        </p>
                    </FadeIn>

                    <FadeIn>
                        <div className="flex flex-row items-center gap-6 justify-center md:justify-start mt-10">
                            <div>
                                <button
                                    onClick={() => setContactModalOpen(true)}
                                    className="bg-primary bg-opacity-30 border border-white border-opacity-10 rounded-full px-6 sm:px-8 py-2 sm:py-3 font-semibold text-xl sm:text-2xl inline-flex items-center gap-3 sm:gap-4 hover:border-opacity-30 transition-colors"
                                >
                                    {t('buttons.primary')}
                                    <ChevronRight/>
                                </button>
                            </div>

                            <Link
                                href="#projecten"
                                className="text-secondary font-semibold text-xl sm:text-2xl inline-flex items-center gap-3 sm:gap-4"
                            >
                                <span dangerouslySetInnerHTML={{__html: t.raw('buttons.secondary')}}/>
                                <div
                                    className="w-10 sm:w-12 h-10 sm:h-12 inline-flex items-center justify-center bg-secondary bg-opacity-20 rounded-full border border-white border-opacity-10 backdrop-blur-lg">
                                    <ArrowDownRightIcon className="w-4 h-4 sm:w-auto sm:h-auto"/>
                                </div>
                            </Link>
                        </div>

                    </FadeIn>
                </FadeInStagger>

                <div className="relative h-96 mt-10 text-left flex justify-center items-center lg:absolute md:top-10 md:left-20 lg:top-40 lg:left-[35rem] xl:md:left-[38rem]">
                    <FadeInStagger className="flex -rotate-12 absolute md:-top-10 md:-left-4">
                        <FadeIn className="z-10 pointer-events-none">
                            <div className="translate-x-40 md:translate-x-48 z-10 opacity-80 hover:-translate-y-12 transition-transform duration-500 pointer-events-auto">
                                <ProjectCard {...heroProjects[1]} hero/>
                            </div>
                        </FadeIn>
                        <FadeIn className="z-20 pointer-events-none">
                            <div className="translate-y-56 z-20 hover:translate-y-48 transition-transform duration-500 pointer-events-auto">
                                <ProjectCard {...heroProjects[0]} hero/>
                            </div>
                        </FadeIn>
                        <FadeIn className="z-0 pointer-events-none">
                            <div className="-translate-x-32 md:-translate-x-64 translate-y-10 md:-translate-y-[17rem] z-0 opacity-50 pointer-events-auto">
                                <ProjectCard {...heroProjects[2]} hero/>
                            </div>
                        </FadeIn>
                    </FadeInStagger>
                </div>
            </Container>
        </div>
    </header>
}
