import {Title} from "@/components/Title";
import headerGridImage from '@/images/process/header-grid.svg'
import headerGridEnImage from '@/images/process/header-grid-en.svg'
import beamImage from '@/images/process/beam.png'
import beamMidImage from '@/images/process/beam-mid.png'
import Image from "next/image";
import {Container} from "@/components/Container";
import {MeetingIcon} from "@/images/MeetingIcon";
import {PrototypeIcon} from "@/images/PrototypeIcon";
import {CodeIcon} from "@/images/CodeIcon";
import {LiveIcon} from "@/images/LiveIcon";
import clsx from "clsx";

import meetingsImage from '@/images/process/meeting/meetings.svg'
import requirementsImage from '@/images/process/meeting/requirements.svg'
import planningImage from '@/images/process/meeting/planning.svg'

import technicalImage from '@/images/process/prototype/technical.svg'
import visualizationImage from '@/images/process/prototype/visualization.svg'

import stackImage from '@/images/process/programming/stack.svg'
import codingImage from '@/images/process/programming/coding.svg'
import feedbackImage from '@/images/process/programming/feedback.svg'

import supportImage from '@/images/process/live/support.png'
import infrastructureImage from '@/images/process/live/infrastructure.png'
import {useLocale, useTranslations} from "next-intl";

function PhaseCard({
                       title,
                       image,
                       imagePaddingX = false,
                       imagePaddingB = false,
                       imagePaddingL = false,
                       imageCentered = false,
                       imageRight = false
                   }) {
    return <div
        className="bg-[#9f97ff] bg-opacity-10 border border-white border-opacity-10 text-left rounded-xl flex-1 max-w-sm">
        <h3 className="font-semibold text-white text-base md:text-xl p-4 md:p-6">{title}</h3>
        <Image src={image} alt="icon" className={clsx(
            'w-full',
            imagePaddingB && 'pb-6',
            imagePaddingX && 'px-6',
            imagePaddingL && 'pl-6',
            imageCentered && 'mx-auto',
            imageRight && 'ml-auto',
        )}/>
    </div>
}

function Phase({number, icon, title, description, children}) {
    return <div className="py-20 relative ">

        <div className="max-w-xl mx-auto text-center">
            <div className="flex justify-center">
                <span className="absolute top-0 w-0.5 h-28 bg-primary rounded-t"></span>
                <div
                    className="w-9 h-9 inline-flex items-center justify-center bg-[#16183E] backdrop-blur-sm bg-opacity-50 text-white font-medium rounded-full border-2 border-primary">
                    <span>0{number}</span>
                </div>
            </div>
            <div className="flex items-center gap-2 justify-center text-secondary mt-4 mb-8">
                {icon}
                <h4 className="font-semibold text-2xl">{title}</h4>
            </div>

            <h3 className="font-semibold text-white text-2xl md:text-3xl">{description}</h3>
        </div>

        {/*<div className="flex justify-center items-start gap-4 md:gap-6 mt-10">*/}
        {children}
        {/*</div>*/}
    </div>
}

export function Process() {
    const t = useTranslations('Process');
    const locale = useLocale();

    return <section className="bg-white" id="werkwijze">
        <div className="bg-midnight section-rounded-b relative overflow-hidden text-center">
            {/*<Image src={beamImage} alt="" className="absolute -top-20 left-0 right-0 mx-auto"/>*/}
            <Image src={locale === 'nl' ? headerGridImage : headerGridEnImage} alt=""
                   className="absolute -top-36 left-1/2 -ml-[37.7rem] w-[75.4rem] md:-ml-[45rem] md:w-[90rem] max-w-none bg-midnight"/>

            <Image src={beamMidImage} alt="" className="absolute top-[40%] right-0"/>

            <Container className="relative mt-[16rem]">
                <Title className="sr-only">{t('title')}</Title>

                <Phase
                    number="1"
                    icon={<MeetingIcon/>}
                    title={t('phases.kick_off.title')}
                    description={t('phases.kick_off.description')}
                >
                    <div className="hidden md:flex justify-center items-start gap-4 md:gap-6 mt-10">
                        <PhaseCard title={t('phases.kick_off.meeting')} image={meetingsImage} imagePaddingX imagePaddingB/>
                        <PhaseCard title={t('phases.kick_off.requirements')} image={requirementsImage}
                                   imagePaddingL/>
                        <PhaseCard title={t('phases.kick_off.roadmap')} image={planningImage} imagePaddingX/>
                    </div>
                    <div className="grid md:hidden grid-cols-2 justify-center items-start gap-4 md:gap-6 mt-10">
                        <div className="flex flex-col gap-4">
                            <PhaseCard title={t('phases.kick_off.meeting')} image={meetingsImage} imagePaddingX imagePaddingB/>
                            <PhaseCard title={t('phases.kick_off.roadmap')} image={planningImage} imagePaddingX/>
                        </div>
                        <PhaseCard title={t('phases.kick_off.requirements')} image={requirementsImage}
                                   imagePaddingL/>
                    </div>
                </Phase>

                <Phase
                    number="2"
                    icon={<PrototypeIcon/>}
                    title={t('phases.design.title')}
                    description={t('phases.design.description')}
                >
                    <div className="flex justify-center items-start gap-4 md:gap-6 mt-10">
                        <PhaseCard title={t('phases.design.visual')} image={visualizationImage} imagePaddingL/>
                        <PhaseCard title={t('phases.design.technical')} image={technicalImage} imagePaddingL/>
                    </div>
                </Phase>

                <Phase
                    number="3"
                    icon={<CodeIcon/>}
                    title={t('phases.development.title')}
                    description={t('phases.development.description')}
                >
                    <div className="hidden md:flex justify-center items-start gap-4 md:gap-6 mt-10">
                        <PhaseCard title={t('phases.development.project')} image={stackImage} imageCentered/>
                        <PhaseCard title={t('phases.development.programming')} image={codingImage} imageRight imagePaddingL/>
                        <PhaseCard title={t('phases.development.feedback')} image={feedbackImage}/>
                    </div>
                    <div className="grid md:hidden grid-cols-2 justify-center items-start gap-4 md:gap-6 mt-10">
                        <div className="flex flex-col gap-4">
                            <PhaseCard title={t('phases.development.project')} image={stackImage} imageCentered/>
                            <PhaseCard title={t('phases.development.programming')} image={codingImage} imageRight imagePaddingL/>
                        </div>
                        <PhaseCard title={t('phases.development.feedback')} image={feedbackImage}/>
                    </div>
                </Phase>

                <Phase
                    number="4"
                    icon={<LiveIcon/>}
                    title={t('phases.launch.title')}
                    description={t('phases.launch.description')}
                >
                    <div className="flex justify-center items-start gap-4 md:gap-6 mt-10">
                        <PhaseCard title={t('phases.launch.support')} image={supportImage}/>
                        <PhaseCard title={t('phases.launch.infrastructure')} image={infrastructureImage}/>
                    </div>
                </Phase>

            </Container>

        </div>
    </section>
}
