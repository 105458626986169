import React, {useState} from "react";
import Image from "next/image";
import {ArrowUpRightIcon} from "@/images/ArrowUpRightIcon";
import {Title} from "@/components/Title";
import ProjectModal from "@/components/ProjectModal";
import clsx from "clsx";

export function ProjectCard({shortTitle, title, image, shortDescription, technologies, testimonials, hero = false, ...props}) {
    let [open, setOpen] = useState(false)

    return <>
        <div className={clsx(
            " cursor-pointer group",
            hero ? 'w-[17rem] md:w-[29rem]' : 'mt-10'
        )} onClick={() => setOpen(true)}>
            <div className={clsx(
                "relative rounded-2xl overflow-hidden border border-gray-400 border-opacity-30 group-hover:border-opacity-50 transition-colors",
                hero ? 'p-6 h-[18rem] md:h-[30rem]' : 'p-10 min-h-[30rem]'
            )}>
                <Image
                    className="absolute inset-0 h-full w-full object-cover bg-white rounded-2xl"
                    src={image}
                    alt=""
                />
                <div className="absolute inset-0 bg-project-card"></div>

                <div className="relative min-h-[27.5rem] flex flex-col justify-between">
                    {/*{!hero &&*/}
                        <button
                            className="w-10 h-10 inline-flex items-center justify-center bg-white bg-opacity-10 rounded-full border border-white border-opacity-20 backdrop-blur-lg absolute top-0 right-0 z-10" aria-label="View project">
                            <ArrowUpRightIcon className="stroke-white h-3 w-3"/>
                        </button>
                    {/*}*/}

                    <div className={clsx(hero ? '' : "")}>
                        <div className={clsx('mb-4', hero ? '' : " md:w-1/2")}>
                            <Title className="text-xl md:text-3xl">{shortTitle ?? title}</Title>
                            <p className="mt-2 md:mt-0 opacity-70 text-lg">{shortDescription}</p>
                        </div>
                        <div className={clsx(
                            "flex gap-2 whitespace-nowrap overflow-x-auto scrollbar-hide relative",
                            hero ? '-mx-6 px-6' : '-mx-10 px-10'
                        )}>
                            {technologies.map((technology, index) => <div key={index}
                                                                          className="flex items-center gap-1 md:gap-2 font-semibold text-sm md:text-lg rounded-full py-1 md:py-1.5 px-2.5 md:px-4 bg-white bg-opacity-10 backdrop-blur border border-white border-opacity-20">
                                {technology.icon.src ?
                                    <div className={clsx("h-6", technology.title ? 'w-6' : 'w-12')}><Image
                                        src={technology.icon}
                                        alt=""
                                        className={clsx("h-4 md:h-6", technology.title ? 'w-4 md:w-6' : 'w-12')}/>
                                    </div> : <technology.icon
                                        className={clsx("h-4 md:h-6 w-4 md:w-6 text-white stroke-white", (technology.title == 'Shopify koppeling' || !technology.title) && 'fill-white')}/>}
                                {technology.title && <span>{technology.title}</span>}
                            </div>)}
                        </div>
                    </div>

                    <div className={clsx(hero ? '' : "md:w-1/2")}>
                        {testimonials[0] &&
                            <div className="flex gap-4">
                                <Image src={testimonials[0].image} alt="" className="h-14 w-14 rounded-full"/>
                                <div className="text-base">
                                    <Title size="xsmall" className="">{testimonials[0].name}</Title>
                                    <p className="text-[#B7B7B7] mb-2">{testimonials[0].function}</p>
                                    <p className="line-clamp-2">{testimonials[0].text}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <ProjectModal
            open={open}
            setOpen={setOpen}
            title={title}
            technologies={technologies}
            testimonials={testimonials}
            {...props}
        />
    </>
}
