import {Dialog, Tab, Transition} from "@headlessui/react";
import {Fragment, useContext, useState} from "react";
import {XIcon} from "@/images/XIcon";
import Image from "next/image";
import clsx from "clsx";
import {Title} from "@/components/Title";
import Cal, {getCalApi} from "@calcom/embed-react";
import {useEffect} from "react";
import Link from "next/link";
import {Modal} from "@/components/general/Modal";
import {ContactModalContext} from "@/context/ContactModalContext";
import whatsappButton from '@/images/contact/whatsapp.svg'
import {useTranslations} from "next-intl";

export function ContactModal() {
    const t = useTranslations('ContactModal');
    const {contactModalOpen, setContactModalOpen} = useContext(ContactModalContext);

    useEffect(() => {
        (async function () {
            const cal = await getCalApi("https://app.cal.com/embed/embed.js");
            cal("ui", {"theme":"light", "styles": {"branding": {"brandColor": "#6F57FF"}}, "hideEventTypeDetails": false});
        })();
    }, [])

    return <Modal open={contactModalOpen} setOpen={setContactModalOpen}>

        <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="p-4 md:p-6 flex flex-col justify-between">
                <div>
                    <div className="flex flex-col gap-2">
                        <Link href="mailto:lorenso@dagostino.digital" className="text-2xl md:text-4xl font-semibold">
                            lorenso@dagostino.digital
                        </Link>
                        <Link href="tel:+31 6 50 277 538" className="text-2xl md:text-4xl font-semibold">
                            +31 6 50 277 538
                        </Link>
                    </div>

                    <p className="mt-6 text-xl" dangerouslySetInnerHTML={{__html: t('description')}}/>
                </div>

                <a aria-label="Chat on WhatsApp" href="https://wa.me/31650277538" target="_blank" className="mt-4">
                    <Image src={whatsappButton} alt="" />
                </a>
                {/*<span className="flex items-center gap-2 mt-4"><div className="w-2 h-2 bg-green-400 rounded-full ring-2 ring-offset-1 ring-green-500"/> Beschikbaar voor nieuwe projecten</span>*/}
            </div>

            <Cal calLink="lorenso-dagostino/30min" style={{width: "100%", height: "100%", overflow: "scroll"}}/>
        </div>
    </Modal>
}
