import {Container} from "@/components/Container";
import {Title} from "@/components/Title";
import {Disclosure} from '@headlessui/react'
import {MinusIcon} from "@/images/MinusIcon";
import {PlusIcon} from "@/images/PlusIcon";
import {useState} from "react";
import clsx from "clsx";

const zeroFaqsGiven = {
    'services': [
        {
            'question': 'Welke diensten lever je precies?',
            'answer': 'Ik doe softwareontwikkeling van A tot Z. Of je nu een webapplicatie, native app, integratie of API wilt laten ontwikkelen. Ik kan je hiermee helpen.'
        },
        {
            'question': 'Welke technieken gebruik je?',
            'answer': 'Ik kijk per project welke technieken het beste passen voor de beste oplossing. Meestal gebruik ik Laravel, MySQL, React, Next.js en/of Flutter.'
        },
        {
            'question': 'Waarom zou ik voor jou kiezen t.o.v. een andere partij?',
            'answer': ''
        },
        {
            'question': 'Waar kan je me niet mee helpen?',
            'answer': 'Technisch verzorg ik alles m.b.t. het ontwikkelen en online houden van een software product. Ik ben geen marketingexpert, teksten kan je dus beter zelf aanlevern.'
        },
    ],
    'pricing': [
        {
            'question': 'Hoeveel kost een project?',
            'answer': 'Dat hangt van het type project af. Ik maak voor elk project een gedetailleerde offerte op maat waarbij ik een precieze urenschatting geef. Het grootste deel van de projecten kost tussen de € 10.000 en € 30.000.'
        },
        {
            'question': 'Hoe lang duurt een project?',
            'answer': 'Projecten variëren in lengte, maar het grootste deel van de projecten duurt tussen 2 en 6 maanden.'
        },
        {
            'question': 'Betaal ik per uur of fixed fee?',
            'answer': 'Het grootste deel van de projecten is fixed fee. Aan de start van het project schat ik zo accuraat mogelijk hoeveel tijd er nodig is. Zo weet je van te voren waar je aan toe bent.'
        },
    ],
    'personal': [
        {
            'question': 'Waar geniet je binnen dit werkt het meest van?',
            'answer': 'Het leuke aan softwareontwikkeling is dat het niet alleen maar op het technische aspect aankomt. Hoewel ik erg kan genieten van het opzetten van een solide stuk code zou dit nutteloos zijn als het niet aansluit bij de wensen van een klant. De vertaalslag van idee tot software vereist veel sociale skills en kan vind ik ook heel leuk.'
        },
        {
            'question': 'Waar sta je over 5 jaar?',
            'answer': ''
        },
        {
            'question': 'Waarom ben je voor jezelf begonnen?',
            'answer': ''
        },
    ]
}

const topics = [
    {key: 'services', title: 'Diensten & Technieken'},
    {key: 'pricing', title: 'Tarieven & Tijd'},
    {key: 'personal', title: 'Persoonlijk'},
]

export function Faq() {
    const [selectedTopic, setSelectedTopic] = useState('services')

    return <section id="faq" className="bg-midnight text-white">
        <Container className="py-20 relative">
            <Title className="text-center relative">FAQs</Title>

            <div className="max-w-3xl mx-auto">
                <div className="flex gap-4 items-center justify-center mt-8 mb-4 overflow-y-auto scrollbar-hide -mx-4 -sm:mx-6 -lg:mx-8">
                    {topics.map((topic) => <button
                        key={topic.key}
                        onClick={() => setSelectedTopic(topic.key)}
                        className={clsx(
                        "px-4 py-1 rounded-full cursor-pointer border ",
                            selectedTopic === topic.key ? 'bg-white bg-opacity-5 border-white border-opacity-[0.07]' : 'opacity-70 border-transparent'
                        )}
                    >
                        <span className="font-semibold text-xl whitespace-nowrap">{topic.title}</span>
                    </button>)}
                </div>

                <div className="divide-y divide-[#3E3753] divide-opacity-90">
                    {zeroFaqsGiven[selectedTopic].map((faq, index) =>
                        <Disclosure key={index} as="div">
                            {({open}) => (
                                <>
                                    <Disclosure.Button
                                        className="flex w-full pr-2 justify-between items-center py-6 text-left text-xl font-semibold">
                                        <span>{faq.question}</span>
                                        {open ? <MinusIcon/> : <PlusIcon/>}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pb-4 text-xl text-white opacity-70">
                                        {faq.answer}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    )}
                </div>

            </div>
        </Container>
    </section>
}
