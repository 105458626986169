import clsx from "clsx";

const colorStyles = {
    midnight: 'text-midnight',
    white: 'text-white',
}

const sizeStyles = {
    xsmall: 'text-xl',
    small: 'text-3xl',
    medium: 'text-5xl',
    normal: 'text-7xl',
    mega: 'text-9xl',
}

export function Title({color = 'white', size = 'normal', className, children}) {
    return <h3 className={clsx(
        'font-semibold',
        colorStyles[color],
        sizeStyles[size],
        className
    )}>
        {children}
    </h3>
}
