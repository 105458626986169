import baasImage from '@/images/about/baas.jpg'
import meImage from '@/images/about/lore.jpg'
import {Container} from "@/components/Container";
import Image from "next/image";
import {Title} from "@/components/Title";
import beamImage from "@/images/about/beam.png";
import lightImage from "@/images/about/light.svg";
import illustrationImage from "@/images/about/illustration.svg";
import signatureImage from "@/images/about/signature.png";
import quoteImage from "@/images/about/quote.svg";
import {useTranslations} from "next-intl";

export function About() {
    const t = useTranslations('About');

    return <section id="over" className="bg-midnight text-center ">

        {/*<Image src={lightImage} alt="" className="absolute -top-3/4 left-0 right-0 mx-auto z-0"/>*/}
        {/*<Image src={beamImage} alt="" className="absolute top-0 left-0 z-0"/>*/}

        <Image src={illustrationImage} alt="" className="absolute top-[52rem] z-0
        left-1/2 -ml-[37.7rem] w-[75.4rem] md:-ml-[45rem] md:w-[90rem] max-w-none
        "/>

        <Container className="pb-20 relative z-10">
            <Image
                src={meImage}
                alt="Lorenso D'Agostino"
                className="rounded-full w-56 h-56 mx-auto my-10"
            />
            <Title className="font-gradient font-effect-hero leading-[1.2]">Lorenso D&apos;Agostino</Title>

            <div className="relative max-w-3xl mx-auto">
                <Image src={quoteImage} alt="" className=" absolute -left-20 top-0"/>

                <p
                    className="text-left opacity-70 text-xl mt-10"
                    dangerouslySetInnerHTML={{__html: t.raw('description')}}
                />

                <Image src={signatureImage} alt="" className="w-44 absolute right-0 -bottom-28 md:-bottom-20"/>
            </div>
        </Container>
    </section>
}
