import mask from '@/images/subhero/mask.png'
import {Container} from "@/components/Container";
import Image from "next/image";
import eventixImage from '@/images/clients/eventix.svg'
import fritsphilipsImage from '@/images/clients/frits-philips.png'
import frontaalImage from '@/images/clients/frontaal.png'
import tfinImage from '@/images/clients/tfin.png'
import digireadyImage from '@/images/clients/chasing.svg'
import growImage from '@/images/clients/grow.svg'
import {CurrencyEuroIcon, FaceFrownIcon, QuestionMarkCircleIcon, XCircleIcon} from "@heroicons/react/24/outline";
import {FadeIn, FadeInStagger} from "@/components/FadeIn";
import {useTranslations} from "next-intl";

export function Problem() {
    const t = useTranslations('Problem');

    const challenges = [
        {
            icon: XCircleIcon,
            title: t('challenges.black_box.title'),
            description: t('challenges.black_box.description'),
        },
        {
            icon: FaceFrownIcon,
            title: t('challenges.wishes.title'),
            description: t('challenges.wishes.description'),
        },
        {
            icon: CurrencyEuroIcon,
            title: t('challenges.costs.title'),
            description: t('challenges.costs.description'),
        }
    ];

    return <section id="problem" data-white="true" className="bg-midnight overflow-hidden">
        <div className="bg-white section-rounded-b">
            <Container className="py-20 md:py-32">
                <FadeIn>
                    <h2
                        className="bg-clip-text text-transparent text-3xl md:text-7xl font-semibold bg-midnight"
                        style={{backgroundImage: `url(${mask.src})`}}
                    >
                        {t('title')}
                    </h2>
                </FadeIn>

                <div className="border-[#DFDAEB] border-b-2 border-dashed my-12"/>

                <FadeInStagger className="grid grid-cols-1 md:grid-cols-3 gap-8 px-8">
                    {challenges.map((challenge, index) =>
                        <FadeIn key={index}>
                            <p className="text-[#9390B1] text-xl">
                                <span className="text-midnight flex gap-1 items-center font-semibold">
                                    <challenge.icon className="w-6 h-6 inline-flex"/>
                                    {challenge.title}
                                </span>
                                {challenge.description}
                            </p>
                        </FadeIn>
                    )}
                </FadeInStagger>
            </Container>
        </div>
    </section>
}
