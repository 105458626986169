import Image from "next/image";
import logo from '@/images/logo/logo-black.svg'
import {Container} from "@/components/Container";
import Link from "next/link";
import {ArrowUpRightIcon} from "@/images/ArrowUpRightIcon";
import {ChevronRight} from "@/images/ChevronRight";
import {getLinks, socials} from "@/content/menu";
import {useContext} from "react";
import {ContactModalContext} from "@/context/ContactModalContext";
import {useTranslations} from "next-intl";
import sbb from '@/images/footer/sbb.png'

export function Footer() {
    const t = useTranslations('Footer');
    const links = getLinks(useTranslations('Menu'))
    const {setContactModalOpen} = useContext(ContactModalContext);

    return <footer data-white="true" className="bg-white">
        <Container className="pt-20 pb-10">
            <div className="flex flex-col text-center md:text-left md:flex-row md:justify-between">
                <div className="text-[#0C0830]">
                    <Image
                        src={logo}
                        alt="D'Agostino Digital"
                        className="mx-auto md:mx-0 h-8 w-auto"
                        priority
                    />
                    <div className="flex flex-col gap-2 mt-10">
                        <Link href="mailto:lorenso@dagostino.digital"
                              className="text-2xl md:text-4xl font-semibold">
                            lorenso@dagostino.digital
                        </Link>
                        <Link href="tel:+31 6 50 277 538" className="text-2xl md:text-4xl font-semibold">
                            +31 6 50 277 538
                        </Link>
                    </div>
                </div>

                <div className="flex flex-col justify-between md:text-right gap-4 mt-10 md:gap-0 md:mt-0">
                    <div className="flex flex-wrap justify-center md:justify-normal gap-2">
                        {links.map((link, index) =>
                            <Link key={index} href={link.link}
                                  className="text-midnight font-medium rounded-full py-1.5 px-4 bg-black bg-opacity-5 border border-black border-opacity-10">
                                {link.title}
                            </Link>
                        )}
                    </div>

                    <div className="order-first md:order-last">
                        <button
                            onClick={() => setContactModalOpen(true)}
                            className="bg-primary font-semibold inline-flex text-xl items-center gap-3 rounded-full px-6 py-3">
                            {t('contact_button')}
                            <ChevronRight/>
                        </button>
                    </div>
                </div>
            </div>

            <div className="border-[#DFDAEB] border-b-2 border-dashed my-10"/>

            <div className="flex flex-col md:flex-row justify-between items-center">
                <Image src={sbb} alt="SBB Beeldmerk" className="w-14 h-14 object-contain mt-2 md:mt-0"/>
                <div
                    className="flex flex-col md:flex-row items-center text-center md:text-left mt-4 md:mt-0 gap-4 text-[#606060] font-normal">
                    <p>© {new Date().getFullYear()} D&apos;Agostino Digital</p>
                    <Link href="/privacy" className="hover:text-midnight">{t('privacy')}</Link>
                    <Link href="/av" className="hover:text-midnight">{t('tac')}</Link>
                    <p>KVK: 87397056</p>
                    <p>BTW: NL004407256B09</p>
                </div>

                <div className="flex gap-2 order-first md:order-last">
                    {socials.map((social) => <a key={social.link} href={social.link} target="_blank" aria-label="Social link">
                        <social.icon />
                    </a>)}
                </div>
            </div>

        </Container>
    </footer>
}
