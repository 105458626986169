import mask from '@/images/subhero/mask.png'
import {Container} from "@/components/Container";
import Image from "next/image";
import eventixImage from '@/images/clients/eventix.svg'
import fritsphilipsImage from '@/images/clients/frits-philips.png'
import frontaalImage from '@/images/clients/frontaal.png'
import tfinImage from '@/images/clients/tfin.png'
import digireadyImage from '@/images/clients/chasing.svg'
import growImage from '@/images/clients/grow.svg'

export function SubHero() {
    return <section id="subhero" data-white="true" className="bg-midnight">
        <div className="bg-white section-rounded-b">
        <Container className="py-20">
            <h2
                className="bg-clip-text text-transparent text-4xl md:text-7xl font-semibold bg-midnight"
                style={{backgroundImage: `url(${mask.src})`}}
            >
                {/*Develop solid backend and stunning frontend with clear process*/}
                Volledige ontzorging in maatwerk (web)app development
            </h2>

            <div className="border-[#DFDAEB] border-b-2 border-dashed my-12"/>

            <div className="grid grid-cols-3 md:grid-cols-6 gap-4 items-center">
                {[eventixImage, fritsphilipsImage, frontaalImage, tfinImage, digireadyImage, growImage].map((image, index) =>
                    <Image key={index} src={image} alt="" className="w-40 h-auto opacity-40 hover:opacity-100 transition-opacity"/>
                )}
            </div>
        </Container>
        </div>
    </section>
}
