import {Container} from "@/components/Container";
import mask from "@/images/subhero/mask.png";
import {
    BeakerIcon,
    CloudIcon,
    DevicePhoneMobileIcon,
    GlobeAltIcon,
    LinkIcon,
    PaintBrushIcon, ServerStackIcon
} from "@heroicons/react/24/outline";
import {useTranslations} from "next-intl";

export function Services() {
    const t = useTranslations('Services');

    const services = [
        {
            icon: GlobeAltIcon,
            title: t('web')
        },
        {
            icon: LinkIcon,
            title: t('tools')
        },
        {
            icon: PaintBrushIcon,
            title: t('ui')
        },
        {
            icon: DevicePhoneMobileIcon,
            title: t('app')
        },
        {
            icon: ServerStackIcon,
            title: t('cloud')
        },
        {
            icon: BeakerIcon,
            title: t('mvp')
        },
    ]

    return <section id="diensten" data-white="true" className="bg-midnight">
        <div className="bg-white section-rounded-b">
            <Container className="py-20 md:py-32">
                <h2
                    className="bg-clip-text text-center text-transparent text-4xl md:text-7xl font-semibold bg-midnight"
                    style={{backgroundImage: `url(${mask.src})`}}
                >
                    {t('title')}
                </h2>

                <div className="border-[#DFDAEB] border-b-2 border-dashed my-12"/>

                <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 md:gap-12 mb-12 px-0 md:px-8 mx-auto">
                    {services.map((service, index) =>
                        <div className="flex" key={index}>
                            <service.icon className="w-6 md:w-8 h-6 md:h-8 text-black mt-0.5" />
                            <p className="ml-2 text-midnight font-semibold text-xl md:text-4xl whitespace-pre-wrap">{service.title}</p>
                        </div>
                    )}
                </div>
            </Container>
        </div>
    </section>
}
