import clsx from "clsx";
import Image from "next/image";
import {Title} from "@/components/Title";
import {Modal} from "@/components/general/Modal";
import React from "react";
import {useKeenSlider} from 'keen-slider/react'
import Zoom from 'react-medium-image-zoom'

const ThumbnailPlugin = (mainRef) => {
    return (slider) => {
        function removeActive() {
            slider.slides.forEach((slide) => {
                slide.classList.remove("active")
            })
        }

        function addActive(idx) {
            slider.slides[idx].classList.add("active")
        }

        function addClickEvents() {
            slider.slides.forEach((slide, idx) => {
                slide.addEventListener("click", () => {
                    if (mainRef.current) mainRef.current.moveToIdx(idx)
                })
            })
        }

        slider.on("created", () => {
            if (!mainRef.current) return
            addActive(slider.track.details.rel)
            addClickEvents()
            mainRef.current.on("animationStarted", (main) => {
                removeActive()
                const next = main.animator.targetIdx || 0
                addActive(main.track.absToRel(next))
                slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
            })
        })
    }
}

const ResizePlugin = (slider) => {
    const observer = new ResizeObserver(function () {
        slider.update()
    })

    slider.on("created", () => {
        observer.observe(slider.container)
    })
    slider.on("destroyed", () => {
        observer.unobserve(slider.container)
    })
}

export default function ProjectModal({open, setOpen, title, description, carousel, testimonials, technologies}) {
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
    }, [ResizePlugin])
    const [thumbnailRef] = useKeenSlider(
        {
            initial: 0,
            slides: {
                perView: 'auto',
                spacing: 15,
            },
        },
        [ThumbnailPlugin(instanceRef)]
    )

    return <Modal open={open} setOpen={setOpen} width="smol">
        <div ref={sliderRef} className="keen-slider rounded-2xl">
            {carousel.map((image, index) =>
                <Zoom key={index}>
                    <Image
                        src={image.src}
                        alt={image.alt}
                        className="keen-slider__slide aspect-[4/3] object-cover object-center"
                    />
                </Zoom>
            )}
        </div>

        <div ref={thumbnailRef} className="keen-slider thumbnail mt-4 p-1">
            {carousel.map((image, index) =>
                <Image
                    key={index}
                    src={image.src}
                    alt={image.alt}
                    className="keen-slider__slide aspect-[4/3] max-h-24 object-cover object-center rounded-md ring-2 ring-offset-2 ring-transparent cursor-pointer"
                />
            )}
        </div>

        <Title color="midnight" size="small" className="mt-6 mb-2">{title}</Title>

        <div className="flex gap-2 whitespace-nowrap overflow-x-auto scrollbar-hide -mx-4 px-4">
            {technologies.map((technology, index) => <div key={index}
                                                          className="flex items-center gap-2 font-semibold text-lg rounded-full py-1.5 px-4 bg-black bg-opacity-5 border border-black border-opacity-10">
                {technology.icon.src ?
                    <div className={clsx("h-6", technology.title ? 'w-6' : 'w-12')}><Image src={technology.icon}
                                                                                           alt=""
                                                                                           className={clsx("h-6", technology.title ? 'w-6' : 'w-12')}/>
                    </div> : <technology.icon className="h-6 w-6"/>}
                {technology.title && <span>{technology.title}</span>}
            </div>)}
        </div>

        <div
            className="prose max-w-full my-8"
            dangerouslySetInnerHTML={{__html: description.replace(/\n/g, "<br />")}}
        />

        {testimonials.length > 0 &&
            <div className="mt-8 mb-4">
                <Title color="midnight" size="xsmall" className="mb-4">Feedback</Title>
                <div className="flex flex-col md:flex-row gap-8 overflow-x-scroll scrollbar-hide -mx-4 px-4">
                    {testimonials.map((testimonial, index) => <div key={index}
                                                                   className="flex gap-4 w-full md:w-[35rem] shrink-0">
                        <Image src={testimonial.image} alt="" className="h-14 w-14 rounded-full"/>
                        <div className="text-base">
                            <Title color="midnight" size="xsmall" className="mb-">{testimonial.name}</Title>
                            <p className="text-[#B7B7B7] mb-2">{testimonial.function}</p>
                            <p dangerouslySetInnerHTML={{__html: testimonial.text.replace(/\n/g, "<br />")}}/>
                        </div>
                    </div>)}
                </div>
            </div>
        }
    </Modal>
}
