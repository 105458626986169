import {Hero} from "@/components/Hero";
import {SubHero} from "@/components/SubHero";
import {Process} from "@/components/Process";
import {Projects} from "@/components/Projects";
import {About} from "@/components/About";
import {Faq} from "@/components/Faq";
import {Footer} from "@/components/Footer";
import {ContactModal} from "@/components/ContactModal";
import {NextSeo} from "next-seo";
import {Problem} from "@/components/Problem";
import {MyApproach} from "@/components/MyApproach";
import {Services} from "@/components/Services";
import {CTA} from "@/components/CTA";
import {useTranslations} from "next-intl";
import Head from "next/head";

export default function Home() {
    const t = useTranslations('Home');

    return <>
        <NextSeo
            title={t('seo.title')}
            description={t('seo.description')}
        />
        <Head>
            <link rel="alternate" hrefLang="nl" href="https://dagostino.digital/"/>
            <link rel="alternate" hrefLang="en" href="https://dagostino.digital/en/"/>
        </Head>

        <Hero/>
        <Problem/>
        <div className="bg-white">
            <div className="overflow-hidden section-rounded-b bg-midnight relative">
                <MyApproach/>
                <About/>
                <CTA/>
            </div>
        </div>
        <Services/>
        <Process/>
        <Projects/>
        <div className="bg-white">
        <div className="overflow-hidden section-rounded-b bg-midnight">
            {/*<Faq/>*/}
            <CTA/>
        </div>
        </div>
        <Footer/>
        <ContactModal/>
    </>
}

export async function getStaticProps(context) {
    return {
        props: {
            messages: (await import(`../../lang/${context.locale}.json`)).default
        }
    };
}
