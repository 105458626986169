import {ContactModalContext} from "@/context/ContactModalContext";
import {ArrowUpRightIcon} from "@/images/ArrowUpRightIcon";
import {
    motion,
    useAnimationFrame,
    useMotionValue,
    useScroll,
    useSpring,
    useTransform,
    useVelocity
} from "framer-motion";
import {wrap} from "@motionone/utils";
import {useContext, useRef} from "react";
import {useTranslations} from "next-intl";

function Content() {
    const t = useTranslations('CTA');

    return <div className="flex gap-6 md:gap-10 items-center">
        <h4 className="text-4xl md:text-9xl font-semibold text-secondary group-hover:text-white transition-colors">
            {t('content')}
        </h4>
        <div
            className="w-14 h-14 md:w-40 md:h-40 inline-flex items-center justify-center bg-[#AA8BEB] bg-opacity-20 rounded-full border border-white border-opacity-10 backdrop-blur-lg">
            <ArrowUpRightIcon className="w-6 h-6 md:w-20 md:h-20"/>
        </div>
    </div>
}

function ParallaxText({children, baseVelocity = 100}) {
    const {setContactModalOpen} = useContext(ContactModalContext);

    const baseX = useMotionValue(0);
    const {scrollY} = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: false
    });

    /**
     * This is a magic wrapping for the length of the text - you
     * have to replace for wrapping that works for you or dynamically
     * calculate
     */
    const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

    const directionFactor = useRef(1);
    useAnimationFrame((t, delta) => {
        let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

        /**
         * This is what changes the direction of the scroll once we
         * switch scrolling directions.
         */
        if (velocityFactor.get() < 0) {
            directionFactor.current = -1;
        } else if (velocityFactor.get() > 0) {
            directionFactor.current = 1;
        }

        moveBy += directionFactor.current * moveBy * velocityFactor.get();

        baseX.set(baseX.get() + moveBy);
    });

    /**
     * The number of times to repeat the child text should be dynamically calculated
     * based on the size of the text and viewport. Likewise, the x motion value is
     * currently wrapped between -20 and -45% - this 25% is derived from the fact
     * we have four children (100% / 4). This would also want deriving from the
     * dynamically generated number of children.
     */
    return (
        <div className="parallax overflow-hidden tracking-[-2px] leading-[0.8] whitespace-nowrap flex flex-nowrap m-0">
            <div className="cursor-pointer" onClick={() => setContactModalOpen(true)}>
                <motion.div className="scroller whitespace-nowrap flex flex-nowrap gap-6 md:gap-10 group" style={{x}}>
                    <Content/>
                    <Content/>
                    <Content/>
                    <Content/>
                </motion.div>
            </div>
        </div>
    );
}

export function CTA() {
    const {setContactModalOpen} = useContext(ContactModalContext);

    return <section className="">
        <div className="bg-midnight py-20 md:py-32 section-rounded-b">
            <ParallaxText baseVelocity={-2}>Framer Motion</ParallaxText>
        </div>
    </section>
}
