import {Dialog, Tab, Transition} from "@headlessui/react";
import {Fragment, useContext, useEffect, useState} from "react";
import {XIcon} from "@/images/XIcon";
import {WhiteContext} from "@/context/WhiteContext";
import clsx from "clsx";

const widths = {
    'smol': 'max-w-3xl',
    'normal': 'max-w-5xl'
}

export function Modal({open, setOpen, children, width = 'normal'}) {
    const {white, setWhite, beforeModalOpen, setBeforeModalOpen} = useContext(WhiteContext);

    useEffect(() => {
        if(open) {
            setBeforeModalOpen(white)
            setWhite(false)
        } else {
            setWhite(beforeModalOpen)
        }
    }, [open])

    return <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-90"/>
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center px-4 pb-8 pt-28 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel
                            className={clsx('w-full transform rounded-3xl bg-white p-4 text-left text-midnight align-middle shadow-xl transition-all', widths[width])}>

                            <button
                                onClick={() => setOpen(false)}
                                className="w-10 h-10 inline-flex items-center justify-center bg-white bg-opacity-10 rounded-full border border-white border-opacity-20 backdrop-blur-lg absolute -top-12 xl:top-0 right-0 xl:-right-12">
                                <XIcon/>
                            </button>

                            {children}
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>

}
